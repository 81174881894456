<script>
  import SvelteMarkdown from 'svelte-markdown';

  import { player } from '../models';
  import { mediaSession } from '../helpers/mediasession';

  /* Objeto con los datos a mostrar */
  export let data = {};

  function play_radio() {
    player.load({
      name: 'Red Enfoques',
      icono_url: process.env.SERVER_URL + data.icono[0].url,
      streaming_url: data.audio_url,
    });
    player.play();
    mediaSession.change(
      'Red Enfoques',
      '',
      data.description_min,
      process.env.SERVER_URL + data.icono[0].url,
    );
  }
</script>

<ul
  class="list-group border rounded shadow-sm mt-3"
  style="margin-bottom: 0 px;padding-bottom: 0 px;"
>
  <li class="list-group-item" style="margin-bottom: 0 px;">
    <div class="row d-flex" data-cy="red-enfoques">
      <div
        class="col-12 text-center d-flex justify-content-center flex-wrap flex-sm-nowrap justify-content-md-start col-sm mb-2 mb-sm-0"
      >
        <div class="d-flex">
          <img
            class="border rounded"
            src={process.env.SERVER_URL + data.icono[0].url}
            width="100 px"
            height="100 px"
            alt="enfoques"
          />
        </div>
        <div
          class="text-center text-sm-left flex-md-grow-0"
          style="margin-left: 11px;min-width: 120px;"
        >
          <span class="d-block" style="font-size: 100%;color: rgb(0,0,0);">
            {data.title_min}<br />
          </span>
          <small style="color: rgb(0,0,0);">{data.description_min}<br /></small>
        </div>
      </div>
      <div
        class="col-12 col-sm-auto d-flex justify-content-center align-items-center flex-wrap flex-sm-grow-1 align-items-sm-center flex-md-grow-0"
      >
        <button
          on:click={play_radio}
          class="btn btn-danger btn-sm justify-content-center align-items-center"
          style="padding: 5px;margin: 5px;"
        >
          <i class="fas fa-play-circle" style="font-size: 36px;" />
        </button>
        <a
          class="btn btn-danger btn-sm justify-content-center align-items-center"
          href={data.download_url}
          style="padding: 5px;margin: 5px;"
        >
          <i class="fas fa-download" style="font-size: 36px;" />
        </a>
      </div>
    </div>
  </li>
</ul>
<div class="card" style="margin-top: 10px;" data-cy="note-card">
  <div class="card-body">
    <h4 class="card-title" style="color: rgb(0,0,0);">{data.title_note}</h4>
    <h4 class="card-title" style="color: rgb(0,0,0);font-size: 16px;">
      {data.subtitle_note}<br />
    </h4>
    <p class="card-text" style="color: rgb(0,0,0);">
      <SvelteMarkdown source={data.description_note} />
    </p>
  </div>
</div>
