<script>
  import { onMount } from 'svelte';
  import {
    Accordion,
    ItemContacto,
    Loading,
    ErrorHandler,
  } from '../components';
  import { getClient, query } from 'svelte-apollo';
  import { GET_CONTACTOS } from '../helpers/query/contactos';

  onMount(async () => {
    window.scrollTo(0, 0);
  });

  const client = getClient();
  const contactos = query(client, { query: GET_CONTACTOS });

  function formmating(data) {
    let contacts = [];
    let id = 0;
    data.forEach((province) => {
      province.locations.forEach((location) => {
        let radios_for_location = [];
        location.radios.forEach((radio) => {
          if (radio.contact != null && radio.contact != '')
            radios_for_location.push({
              id: radio.id,
              name: radio.name,
              contact: radio.contact,
            });
        });
        if (radios_for_location.length != 0) {
          contacts.push({
            id: id,
            title: province.name + ' - ' + location.name,
            items: radios_for_location,
          });
          id += 1;
        }
      });
    });
    return contacts;
  }
</script>

<div class="sidebar-brand-text mx-3">
  <span
    style="color: rgb(255,255,255);font-size: 20px;"
    data-cy="contacts-label"
  >
    Contactos<br />
  </span>
</div>

{#await $contactos}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:then result}
  <Accordion
    items={formmating(result.data.provinces)}
    list_component={ItemContacto}
  />
{:catch error}
  <ErrorHandler {error} />
{/await}
