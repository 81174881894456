<script>
  import { player } from '../../models';
  import { mediaSession } from '../../helpers/mediasession';
  import { radios_store, emisoras_store } from '../../store';

  export let id,
    name,
    social_links,
    location,
    icono,
    url_streaming,
    fm = '',
    country = '',
    fav = false,
    redenfoques = false,
    index;

  function change_favorite() {
    redenfoques ? radios_store.change_fav(id) : emisoras_store.change_fav(id);
  }

  if ($player.state == 'init')
    player.load({
      name: name,
      fm: fm,
      icono_url: process.env.SERVER_URL + icono.url,
      streaming_url: url_streaming,
    });

  function play_radio() {
    player.load({
      name: name,
      fm: fm,
      icono_url: process.env.SERVER_URL + icono.url,
      streaming_url: url_streaming,
    });
    player.play();
    if (location)
      mediaSession.change(
        name,
        fm,
        location.name,
        process.env.SERVER_URL + icono.url,
      );
    else
      mediaSession.change(
        name,
        country,
        fm ? fm : '',
        process.env.SERVER_URL + icono.url,
      );
  }
</script>

<div class="row d-flex">
  <div
    class="col-12 text-center d-flex align-items-center align-items-lg-start justify-content-center flex-wrap flex-sm-nowrap justify-content-md-start col-sm mb-2 mb-sm-0"
  >
    <div class="d-flex">
      <img
        class="border rounded"
        src={process.env.SERVER_URL + icono.url}
        alt={'icono radio ' + name}
      />
    </div>
    <div
      class="text-center text-md-left flex-md-grow-0"
      style="margin-left: 11px; min-width: 120px;"
    >
      <span class="d-block" data-cy={`radio-name-${index}`}>
        {name}<br />
      </span>
      <small>{location ? location.name : country}<br /></small>
    </div>
  </div>

  <div
    class="col-12 col-sm-auto d-flex justify-content-center align-items-center flex-wrap flex-sm-grow-1 align-items-sm-center flex-md-grow-0"
  >
    {#if social_links.length > 0}
      <div
        class="dropdown"
        style="width: 0 px;height: 0 px;margin: 0 px;padding: 0 px;font-size: 19px;"
      >
        <button
          class="btn btn-dark dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          type="button"
          style="margin: 0px;padding: 5px;background-color: rgb(0,0,0);margin-bottom: 5px;"
          aria-label="redes sociales de radio 1"
          data-cy={`social-btn-${index}`}
        >
          <i
            class="fa fa-th-list"
            style="font-size: 36px; margin-right: 5px;"
          />
        </button>
        <div class="dropdown-menu" role="menu">
          {#each social_links as social_link (social_link.id)}
            <a
              class="dropdown-item"
              role="presentation"
              href={social_link.link}
            >
              <i
                class={social_link.social_link_type.icono +
                  ' fa-sm fa-fw mr-2 text-gray-400'}
              />{social_link.social_link_type.title}
            </a>
          {/each}
        </div>
      </div>
    {/if}
    <button
      class="btn btn-danger btn-sm justify-content-center align-items-center"
      on:click={change_favorite}
      style="padding: 5px;margin: 5px;margin-bottom: 5;margin-right: 0;margin-top: 0;"
      aria-label={'marcar favorita radio ' + id}
      data-cy={`fav-btn-${index}`}
    >
      {#if fav}
        <i class="fas fa-star" style="font-size: 36px;" />
      {:else}
        <i class="far fa-star" style="font-size: 36px;" />
      {/if}
    </button>
    <button
      class="btn btn-danger btn-sm justify-content-center align-items-center"
      id={`boton_radio${id}`}
      on:click={play_radio}
      style="padding: 5px;margin: 5px;margin-bottom: 5;margin-right: 0;margin-top: 0;"
      aria-label={'reproducir radio ' + id}
      data-cy={`play-btn-${id}`}
    >
      <i class="fas fa-play-circle" style="font-size: 36px;" />
    </button>
  </div>
</div>

<style>
  img {
    width: 100px;
    height: 100px;
  }
  span {
    font-size: 100%;
    color: rgb(0, 0, 0);
  }
  small {
    color: rgb(0, 0, 0);
  }
</style>
