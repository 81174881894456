<script>
  export let location;
  console.log(location.href);
</script>

<div class="sidebar-brand-text mx-3">
  <span style="color: rgb(255,255,255);font-size: 20px;">
    Error 404: La página no existe.
  </span>
</div>
