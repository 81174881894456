<script>
  import { onMount } from 'svelte';
  import { Loading, ErrorHandler, Enfoques } from '../components';
  import { getClient, query } from 'svelte-apollo';
  import { GET_ENFOQUES } from '../helpers/query/enfoques';
  import { mediaSession } from '../helpers/mediasession';

  onMount(async () => {
    window.scrollTo(0, 0);
    mediaSession.init();
  });

  const client = getClient();
  const enfoques = query(client, { query: GET_ENFOQUES });
</script>

<div class="sidebar-brand-text mx-3">
  <span
    style="color: rgb(255,255,255);font-size: 20px;"
    data-cy="enfoques-label"
  >
    Enfoques<br />
  </span>
</div>

{#await $enfoques}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:then result}
  <Enfoques data={result.data.enfoque} />
{:catch error}
  <ErrorHandler {error} />
{/await}
