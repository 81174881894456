import { writable } from 'svelte/store'
import { Repose, Pause, Playing } from '.'

/* Solucionar dependencias circulares */
const STATES = {
  REPOSE: new Repose(),
  PAUSE: new Pause(),
  PLAYING: new Playing()
}

class Player {
  constructor() {
    const { subscribe, set, update } = writable({})
    this.subscribe = subscribe
    this.set = set
    this.update = update

    this.sound = document.createElement('audio')
    this.state = new Repose()
    this.state.load(this, { state: 'init' })
    this.loaded_sound = false
  }

  change_state(state) {
    this.state = STATES[state]
  }

  play() {
    this.state.play(this)
  }

  pause() {
    this.state.pause(this)
  }

  load(radio) {
    this.sound.setAttribute('src', radio.streaming_url)
    let data = {
      state: 'paused',
      name: radio.name,
      fm: radio.fm,
      icono_url: radio.icono_url
    }
    this.loaded_sound = true
    this.state.load(this, data)
  }
}

export const player = new Player()
