import State from './state';

export default class Pause extends State {
  load(context) {
    context.change_state('REPOSE');
  }

  play(context) {
    context.change_state('PLAYING');
    context.play();
  }

  pause(context) {
    context.sound.pause();
    context.update((player) => {
      player['state'] = 'paused';
      return player;
    });
  }
}
