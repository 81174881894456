import { gql } from "@apollo/client/core"

export const GET_PERIODICOS = gql`
{
    periodicos{
      id
      name: nombre
      icono {
        url
      }
      social_links: enlaces_sociales {
        id
        link: enlace
        social_link_type: tipo_enlace_social {
          title: titulo
          icono
        }
        
      }
      
    }
  }
`