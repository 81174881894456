<script>
  import Loading from './Loading.svelte';
  import { player } from '../models';
  let width, y;
  player.subscribe((value) => {
    console.log(value);
  });

  function play() {
    player.play();
  }

  function pause() {
    player.pause();
  }
</script>

<svelte:window bind:scrollY={y} />
<footer
  bind:clientWidth={width}
  class="sticky-footer container-fluid fixed-bottom bg-dark "
>
  <hr />
  <div class="row" style="padding: 0px;margin: 5px;" data-cy="player">
    <div class="col d-flex justify-content-center align-items-center">
      {#if $player.state != 'init'}
        <div class="mr-3">
          {#if $player.state == 'loading'}
            <div class="d-flex justify-content-center align-items-center">
              <Loading size={50} />
            </div>
          {:else}
            <img
              class="d-flex justify-content-start border rounded"
              src={$player.icono_url}
              style="padding-left: 0;"
              alt="icono radio actual"
              height="60w"
              width="60w"
            />
          {/if}
        </div>

        {#if $player.state != 'error'}
          <div
            class="text-center text-sm-left d-none d-sm-inline flex-md-grow-0"
            style="margin-left: 11px;min-width: 150px;"
          >
            <span
              class="d-block"
              style="font-size: 100%;color: rgb(255,255,255);"
              data-cy="radio-name"
            >
              {$player.name}
              <br />
            </span>
            {#if $player.fm}
              <small style="color: rgb(255,255,255);">{$player.fm}<br /></small>
            {/if}
          </div>
        {:else}
          <div
            class="text-center text-sm-left d-none d-sm-inline flex-md-grow-0"
            style="margin-left: 11px;min-width: 150px;"
          >
            <span
              class="d-block"
              style="font-size: 100%;color: rgb(255,255,255);text-align: center;"
            >
              Por el momento<br /> sin streaming<br />
            </span>
          </div>
        {/if}
      {:else}
        <div
          class="text-center text-sm-left d-flex  align-items-center"
          style="margin-left: 11px;min-width: 120px;"
        >
          <span
            class="d-block"
            style="font-size: 100%;color: rgb(255,255,255);text-align: center;"
          >
            Escoge una radio<br /> a reproducir<br />
          </span>
        </div>
      {/if}
      {#if $player.state != 'playing'}
        <button
          class="btn btn-danger btn-sm d-xl-flex justify-content-center align-items-center align-items-xl-center"
          style="padding: 5px;margin: 5px; height: 50 px;width: 50px;"
          aria-label="reproducir radio"
          on:click={play}
          data-cy="play-radio"
        >
          {#if $player.state != 'error'}
            <i class="icon ion-play" style="font-size: 29px;" />
          {:else}
            <i class="icon ion-android-cancel" style="font-size: 29px;" />
          {/if}
        </button>
      {:else}
        <button
          class="btn btn-danger btn-sm d-xl-flex justify-content-center align-items-center align-items-xl-center"
          style="padding: 5px;margin: 5px; height: 50 px;width: 50px;"
          aria-label="reproducir radio"
          on:click={pause}
          data-cy="pause-radio"
        >
          <i class="icon ion-pause" style="font-size: 29px;" />
        </button>
      {/if}
    </div>
  </div>
  {#if width <= 768 && y > 200}
    <a
      class="border rounded d-inline scroll-to-top"
      href="#page-top"
      aria-label="subir"
    >
      <i class="fas fa-angle-up" />
    </a>
  {/if}
</footer>
