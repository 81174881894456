<script>
  import { link } from 'svelte-spa-router';
  import { onMount } from 'svelte';
  import { List, ItemRadio, ErrorHandler, Loading } from '../components';
  import { mediaSession } from '../helpers/mediasession';
  import { new_events, radios_store } from '../store';

  onMount(async () => {
    window.scrollTo(0, 0);
    mediaSession.init();
  });

  new_events.init();
</script>

<a
  class="btn btn-dark d-md-none"
  style="background-color: rgb(0,0,0); margin-bottom: 10px;"
  href="/emisoras"
  use:link
>
  <i class="icon ion-radio-waves" /> Otras emisoras
</a>
{#if $radios_store['state'].loading}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:else if $radios_store['state'].error}
  <div class="sidebar-brand-text mx-3" style="margin-top: 10px;">
    <span
      style="color: rgb(255,255,255);font-size: 20px;"
      data-cy="radio-label"
    >
      Radios<br />
    </span>
  </div>
  <ErrorHandler error={$radios_store['state'].error} _datacy="loading-error" />
{:else}
  {#if $radios_store.radios_favs.length > 0}
    <div class="sidebar-brand-text mx-3">
      <span style="color: rgb(255,255,255);font-size: 20px;">
        Radios favoritas<br />
      </span>
    </div>
    <List
      items={$radios_store.radios_favs}
      component={ItemRadio}
      _datacy="fav-radios-list"
      _class={'border rounded shadow-sm mt-3'}
    />
  {/if}
  {#if $radios_store.radios_nofavs.length > 0}
    <div class="sidebar-brand-text mx-3" style="margin-top: 10px;">
      <span
        data-cy="radio-label"
        style="color: rgb(255,255,255);font-size: 20px;"
      >
        Radios<br />
      </span>
    </div>
    <List
      items={$radios_store.radios_nofavs}
      component={ItemRadio}
      _datacy="radios-list"
      _class={'border rounded shadow-sm mt-3'}
    />
  {/if}
{/if}
