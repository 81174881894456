<script>
  import dateFormat from 'dateformat';
  import SvelteMarkdown from 'svelte-markdown';

  import { onMount } from 'svelte';
  import { Loading, ErrorHandler } from '../components';
  import { getClient, query } from 'svelte-apollo';
  import { GET_EVENTOS } from '../helpers/query/eventos';
  import { new_events } from '../store';

  onMount(async () => {
    window.scrollTo(0, 0);
    new_events.update_alert();
  });

  const client = getClient();
  const eventos = query(client, { query: GET_EVENTOS });
</script>

<div class="sidebar-brand-text mx-3">
  <span style="color: rgb(255,255,255);font-size: 20px;" data-cy="events-label">
    Eventos<br />
  </span>
</div>

{#await $eventos}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:then result}
  {#if result.data.eventos.length === 0}
    <div class={'alert m-4 ' + 'alert-danger'} role="alert" data-cy="no-events">
      No se registran nuevos eventos
    </div>
  {:else}
    <div data-cy="events-card">
      {#each result.data.eventos as noticia (noticia.id)}
        <div
          class="card"
          style="margin: 0 px;margin-top: 10px;margin-bottom: 10px;"
        >
          <div class="card-body">
            <h4 class="card-title" style="color: rgb(0,0,0);">
              {noticia.title}
            </h4>
            <h4 class="card-title" style="color: rgb(0,0,0);font-size: 16px;">
              Fecha del evento: {dateFormat(noticia.date, 'yy/mm/dd')}<br />
            </h4>
            <p class="card-text" style="color: rgb(0,0,0);">
              <SvelteMarkdown
                source={noticia.description.substring(0, 400) + '...'}
              />
            </p>
            <a class="card-link" href={noticia.link}> enlace </a>
          </div>
        </div>
      {/each}
    </div>
  {/if}
{:catch error}
  <ErrorHandler {error} />
{/await}
