import { gql } from "@apollo/client/core"

export const GET_ENFOQUES = gql`
{
	enfoque {
    title_min: titulo_miniatura
    description_min: descripcion_miniatura
    audio_url
    download_url: descarga_url
    icono{
      url
    }
    title_note: titulo_nota
    subtitle_note: subtitulo_nota
    description_note: descripcion_nota
  }
}
`