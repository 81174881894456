<script>
  import { link } from 'svelte-spa-router';

  /* Controla si la pantalla es de movil. Se inicia con el menu cerrado */
  (function ($) {
    $(document).ready(() => {
      if ($(window).width() < 768)
        if (!$('.sidebar').hasClass('toggled')) {
          $('body').toggleClass('sidebar-toggled');
          $('.sidebar').toggleClass('toggled');
        }

      // if ($('.sidebar').hasClass('toggled'))
      // $('.sidebar .collapse').collapse('hide');
    });
    $(window).on('load', () => {
      $('#wrapper').on('swiperight', '#content-wrapper', (event) => {
        if ($('.sidebar').hasClass('toggled')) {
          $('body').toggleClass('sidebar-toggled');
          $('.sidebar').toggleClass('toggled');
        }
      });
      $('#wrapper').on('swipeleft', '#content-wrapper', (event) => {
        if (!$('.sidebar').hasClass('toggled')) {
          $('body').toggleClass('sidebar-toggled');
          $('.sidebar').toggleClass('toggled');
        }
      });
    });
  })(jQuery);
</script>

<nav
  class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0"
>
  <div class="container-fluid d-flex flex-column p-0">
    <a
      class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
      aria-label="inicio"
      href="/"
      use:link
      data-cy="home-btn"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        <img
          src="assets/img/fist-152785_960_720.png"
          alt="puño"
          width="44.1 px"
          height="63 px"
        />
      </div>
    </a>
    <hr class="sidebar-divider my-0" />
    <ul
      class="nav navbar-nav text-light"
      id="accordionSidebar"
      style="padding-bottom: 80px;"
    >
      <li class="nav-item" role="presentation">
        <a class="nav-link buttonToggle" href="/" use:link data-cy="radio-btn">
          <i class="material-icons">radio</i>
          <span>Radios</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link buttonToggle"
          href="/otros-medios"
          use:link
          data-cy="newspaper-btn"
        >
          <i class="far fa-newspaper" />
          <span>Otros Medios</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link buttonToggle"
          href="/enfoques"
          use:link
          data-cy="enfoques-btn"
        >
          <i class="fas fa-microphone" />
          <span>Escuchar último Enfoques</span>
        </a>
      </li>
      <li class="nav-item buttonToggle" role="presentation">
        <a class="nav-link" href="/eventos" use:link data-cy="events-btn">
          <i class="fas fa-star" />
          <span>Eventos</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link buttonToggle"
          href="/emisoras"
          use:link
          data-cy="broadcasting-btn"
        >
          <i class="icon ion-radio-waves" />
          <span>Otras emisoras</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          href="https://iskra.redenfoques.com.ar/login"
          data-cy="iskra-btn"
        >
          <i class="fab fa-hotjar" />
          <span>Iskra</span>
        </a>
      </li>
      <li class="nav-item buttonToggle" role="presentation">
        <a class="nav-link" href="/contactos" use:link data-cy="contacts-btn">
          <i class="fas fa-envelope-open-text" />
          <span>Contactos</span>
        </a>
      </li>
      <li class="nav-item buttonToggle" role="presentation">
        <a class="nav-link" href="/ayuda" use:link data-cy="help-btn">
          <i class="fas fa-info-circle" />
          <span>Ayuda y acerca de nosotros</span>
        </a>
      </li>
    </ul>
    <div class="text-center d-none d-md-inline">
      <button
        class="btn rounded-circle border-0"
        id="sidebarToggle"
        type="button"
        aria-label="cerrar menu lateral"
      />
    </div>
  </div>
</nav>
