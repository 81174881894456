import { writable } from 'svelte/store'
import { getClient } from "svelte-apollo"

function createStoreRadios() {
	
  const { subscribe, update } = writable({
    state: {
      loading: true,
      error: false,
    },
    radios_favs: [],
    radios_nofavs: []
  })
    
  return {
    subscribe,
    init: async (query_) => {
      try {
        let radios_all = null
        /* Busco en el servidor todas las radios */
        await getClient().query({query: query_})
          .then(res => {
            radios_all = res.data.radios
          })
        /* Transformo la data para un acceso más rapido */
        let radios_iid = radios_all.reduce((trans, radio)=>{
          trans[radio.id] = radio
          return trans
        },{})
        /* Busco en local las ids de las radios favoritas */
        const persist = localStorage.getItem("favs")
        const favs = persist ? JSON.parse(persist) : []
        /* Procesamiento para obtener las radios ORDENADAS */
        let radios_favs = favs.reduce((radios_favs, id)=>{
          radios_favs.push(radios_iid[id])
          delete radios_iid[id]
          return radios_favs
        },[])
        let radios_nofavs = Object.values(radios_iid)
        /* Agrego el atributo fav para mostrar la estrella rellena */
        radios_favs = radios_favs.map((item) => ({
          ...item,
          fav: true       
        }));
        /* Guardo los datos */
        localStorage.setItem("favs", JSON.stringify(favs))
        update( prev => {
          prev["radios_favs"] = radios_favs
          prev["radios_nofavs"] = radios_nofavs
          return prev
        })
      } catch(e) {
        update(prev => {
          prev["state"]["error"] = e
          return prev
        })
      }
      update(prev => {
        prev["state"]["loading"] = false
        return prev
      })
    },
    change_fav: (id) => {
      update(prev =>{
        let new_fav = prev["radios_nofavs"].filter((radio) => radio.id ==id)[0]
        /* Se agrega a favoritos */
        if (new_fav){
          const persist = localStorage.getItem("favs")
          localStorage.setItem("favs", JSON.stringify([...JSON.parse(persist), parseInt(id)]))      
          prev["radios_favs"] = [...prev["radios_favs"], Object.assign({}, new_fav, {fav:true})],
          prev["radios_nofavs"] = prev["radios_nofavs"].filter((radio) => radio.id !=id)
        }else{
          /* Se quita de favoritos */
          const persist = localStorage.getItem("favs")
          localStorage.setItem("favs", JSON.stringify(JSON.parse(persist).filter((id_) => id_ != parseInt(id))))
          let removed_fav = prev["radios_favs"].filter((radio) => radio.id ==id)[0]
          if (removed_fav)
            prev["radios_nofavs"] = [Object.assign({}, removed_fav, {fav:false}), ...prev["radios_nofavs"]],
            prev["radios_favs"] = prev["radios_favs"].filter((radio) => radio.id !=id)
        }
        return prev
      })
    }
  }
}

export const radios_store = createStoreRadios()