import State from "./state"

export default class Playing extends State {
  load(context, data) {
    context.set(data)
    context.sound.pause()
  }

  play(context) {
    context.update((player) => {
      player["state"] = "loading"
      return player
    })
    var playPromise = context.sound.play()
    if (playPromise !== undefined) 
      playPromise
        .then(
          function () {
            context.update((player) => {
              player["state"] = "playing"
              return player
            })
          }.bind(this)
        )
        .catch(
          function (error) {
            context.update((player) => {
              player["state"] = "error"
              return player
            })
          }.bind(context)
        )
  }

  pause(context) {
    context.change_state("PAUSE")
    context.pause()
  }
}
