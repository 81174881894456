import { gql } from "@apollo/client/core"

export const GET_AYUDAS = gql`
{
  ayudas(where: { visible: true }, sort: "orden:asc") {
    id
    title: titulo
    subtitle: subtitulo
    description: descripcion
	}
}
`