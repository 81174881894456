import { writable } from 'svelte/store'

function createEvent() {
	
  const { subscribe, set } = writable({
    name: null,
  })
    
  return {
    subscribe,
    addEvent: (name="default") => {
      set({
        name
      })
    },
    removeEvent: () =>{
      set({
        name: null
      })
    }
  }      
} 

export const event = createEvent()