import { gql } from "@apollo/client/core"
import dateFormat from 'dateformat'

var day = new Date()
day.setDate(day.getDate() - 7)

export const GET_EVENTOS = gql`
{
	eventos(sort: "fecha:desc", where: { visible: true fecha_gte: "${dateFormat(day, "yyyy-mm-dd")+"T00:00:00.000Z"}"}){
    id
    title: titulo
    date: fecha
    link: enlace
    description: descripcion
  }
}
`

export const GET_EVENTOS_ID = gql`
{
	eventos(sort: "id:desc", where: { visible: true fecha_gte: "${dateFormat(day, "yyyy-mm-dd")+"T00:00:00.000Z"}"}){
    id
  }
}
`