import State from "./state"

export default class Repose extends State {
  load(context, data) {
    context.set(data)
  }

  play(context) {
    if (context.loaded_sound == true) {
      context.change_state("PLAYING")
      context.play()
    }
  }
}
