<script>
  import { check_mobile_tablet } from '../helpers/detectmobilebrowser';

  function share() {
    if (navigator.share)
      navigator
        .share({
          title: 'Red Enfoques app',
          text: 'Red Enfoques',
          url: 'https://play.google.com/store/apps/details?id=com.radioscomunitarias&hl=es',
        })
        .then(() => console.log('Compartido con exito'))
        .catch((error) => console.log('Error al compartir', error));
  }
</script>

<nav
  class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top"
>
  <div class="container-fluid">
    <button
      id="sidebarToggleTop"
      aria-label="menu"
      class="btn btn-danger d-md-none btn-sm justify-content-center align-items-center"
    >
      <i class="fas fa-bars" style="font-size: 28px;" />
    </button>
    <div class="sidebar-brand-text mx-3">
      <span style="color: rgb(255,255,255);font-size: 20px;"
        >Red Enfoques<br /></span
      >
    </div>
    {#if check_mobile_tablet()}
      <button
        on:click={share}
        class="btn btn-danger btn-sm justify-content-center align-items-center"
        style="margin: 5px;margin-bottom: 0;margin-right: 0;margin-top: 0;"
        aria-label="compartir"
      >
        <i class="fas fa-share-alt" style="font-size: 28px;" />
      </button>
    {:else}
      <a
        class="btn btn-danger btn-sm justify-content-center align-items-center"
        href="https://play.google.com/store/apps/details?id=com.radioscomunitarias&hl=es_AR&gl=US"
        style="margin: 5px;margin-bottom: 0;margin-right: 0;margin-top: 0;"
        aria-label="compartir"
      >
        <i class="fas fa-share-alt" style="font-size: 28px;" />
      </a>
    {/if}
  </div>
</nav>
