export default class State {
  load() {
    /* Implementadas por los hijos */
  }

  pause() {
    /* Implementadas por los hijos */
  }

  play() {
    /* Implementadas por los hijos */
  }
}
