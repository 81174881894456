<script>
  import apolloClient from './config/svelte-apollo';

  import {
    Template,
    Radios,
    Periodicos,
    Enfoques,
    Eventos,
    Contactos,
    Emisoras,
    Ayuda,
    NotFound,
  } from './pages';
  import Router from 'svelte-spa-router';
  import { setClient } from 'svelte-apollo';

  setClient(apolloClient);

  const routes = {
    '/': Radios,
    '/otros-medios': Periodicos,
    '/enfoques': Enfoques,
    '/eventos': Eventos,
    '/emisoras': Emisoras,
    '/contactos': Contactos,
    '/ayuda': Ayuda,
    '*': NotFound,
  };
</script>

<body>
  <Template>
    <Router {routes} />
  </Template>
</body>
