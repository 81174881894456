<script>
  export let items = [],
    _class = '',
    _datacy = '',
    component = null;
</script>

<ul class={'list-group list-custom' + _class} data-cy={_datacy}>
  {#each items as item, index (item.id)}
    <li class="list-group-item item-custom">
      <svelte:component this={component} {...{ ...item, index }} />
    </li>
  {/each}
</ul>

<style>
  .list-custom {
    margin-bottom: 0 px;
    padding-bottom: 0 px;
  }
  .item-custom {
    margin-bottom: 0 px;
  }
</style>
