<script>
  export let name, contact, id;
</script>

<div class="row">
  <div class="col">
    <span> {name} </span>
  </div>
</div>
<div class="row">
  <div class="col" id={'contacto-' + id}>
    <span>{contact}</span>
  </div>
</div>

<style>
  span {
    color: rgb(0, 0, 0);
  }
</style>
