import { gql } from "@apollo/client/core"

export const GET_CONTACTOS = gql`
{
	provinces: provincias {
    id
    name: nombre
    locations: localidades {
      name: nombre
      radios {
        id
        name: nombre
        contact: contacto
      }
    }
  }
}
`