import { gql } from "@apollo/client/core"

export const GET_RADIOS = gql`
{
  radios (where: { redenfoques: true }){
    id
    name: nombre
    url_streaming
    fm: frecuencia
    redenfoques
    location: localidad {
      name: nombre
    }
    icono {
      url
    }
    social_links: enlaces_sociales {
      id
      link: enlace
      social_link_type: tipo_enlace_social {
        title: titulo
        icono
      }
    }
  }
}
`

export const GET_EMISORAS = gql`
{
  radios (where: { redenfoques: false }){
    id
    name: nombre
    url_streaming
    country : pais
    fm: frecuencia
    redenfoques
    location: localidad {
      name: nombre
    }
    icono {
      url
    }
    social_links: enlaces_sociales {
      id
      link: enlace
      social_link_type: tipo_enlace_social {
        title: titulo
        icono
      }
    }
  }
}
`