<script>
  import { Sidebar, Header, Player } from '../components';
  import { event } from '../store';
  import { GET_RADIOS, GET_EMISORAS } from '../helpers/query/radio.js';
  import { radios_store, emisoras_store } from '../store';
  import { link } from 'svelte-spa-router';

  emisoras_store.init(GET_EMISORAS);
  radios_store.init(GET_RADIOS);

  emisoras_store.subscribe((value) => {
    console.log(value);
  });
  radios_store.subscribe((value) => {
    console.log(value);
  });
</script>

<svelte:head>
  <script src="assets/js/theme.js"></script>
  <script>
    (function (w, d, s, l, i) {
      if (process.env.NODE_ENV === 'production') {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=GTM-PSLZD7C' + i + dl;
        f.parentNode.insertBefore(j, f);
      }
    })(window, document, 'script', 'dataLayer', '');
  </script>
</svelte:head>

<div id="wrapper">
  <Sidebar />

  <div
    class="d-flex flex-column"
    id="content-wrapper"
    style="background-color: #D83B3B;"
  >
    <div id="content">
      <Header />
      <!--
      {#if $alert.state}
        <div class={'alert m-4 ' + 'alert-' + $alert.state} role="alert">
          {$alert.message}
        </div>
        {/if}
        -->
      {#if $event.name === 'newNews'}
        <a
          class="btn btn-dark m-4"
          style="background-color: rgb(0,0,0); margin-bottom: 10px;"
          href="/eventos"
          use:link
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-bell"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
            />
          </svg> Próximos eventos
        </a>
      {/if}

      <div class="container-fluid overflow-auto" style="padding-bottom: 125px;">
        <div class="row">
          <div class="col text-center">
            <p class="col-form-label" style="color: rgb(255,255,255);">
              Red Enfoques enlazando las radios comunitarias y populares de la
              patagonia
            </p>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </div>
  <Player />
</div>
