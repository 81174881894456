<script>
  import { link } from 'svelte-spa-router';
  import { onMount } from 'svelte';
  import { List, ItemRadio, Loading, ErrorHandler } from '../components';
  import { emisoras_store } from '../store';
  import { mediaSession } from '../helpers/mediasession';

  onMount(async () => {
    mediaSession.init();
    window.scrollTo(0, 0);
  });
</script>

<a
  class="btn btn-dark d-md-none"
  style="background-color: rgb(0,0,0); margin-bottom: 10px;"
  href="/"
  use:link
>
  <i class="material-icons" style="font-size: 15px;"> radio </i>
  Radios Red Enfoques
</a>
{#if $emisoras_store['state'].loading}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:else if $emisoras_store['state'].error}
  <div class="sidebar-brand-text mx-3" style="margin-top: 10px;">
    <span
      style="color: rgb(255,255,255);font-size: 20px;"
      data-cy="broadcasting-label"
    >
      Emisoras<br />
    </span>
  </div>
  <ErrorHandler error={$emisoras_store['state'].error} />
{:else}
  {#if $emisoras_store.emisoras_favs.length > 0}
    <div class="sidebar-brand-text mx-3">
      <span style="color: rgb(255,255,255);font-size: 20px;">
        Emisoras favoritas<br />
      </span>
    </div>
    <List
      items={$emisoras_store.emisoras_favs}
      component={ItemRadio}
      _class={'border rounded shadow-sm mt-3'}
      _datacy="fav-emisoras-list"
    />
  {/if}
  {#if $emisoras_store.emisoras_nofavs.length > 0}
    <div class="sidebar-brand-text mx-3" style="margin-top: 10px;">
      <span
        style="color: rgb(255,255,255);font-size: 20px;"
        data-cy="broadcasting-label"
      >
        Emisoras<br />
      </span>
    </div>
    <List
      items={$emisoras_store.emisoras_nofavs}
      component={ItemRadio}
      _class={'border rounded shadow-sm mt-3'}
      _datacy="emisoras-list"
    />
  {/if}
{/if}
