<script>
  export let id, name, icono, social_links;
</script>

<div class="row">
  <div class="col-12 col-sm-4 col-md-3 col-xl-2">
    <div class="d-flex justify-content-center">
      <img
        class="border rounded"
        src={process.env.SERVER_URL + icono.url}
        alt={'periodico ' + id}
      />
    </div>
    <span class="d-flex justify-content-center text-center">{name}<br /></span>
  </div>
  <div class="col-12 col-sm-8 col-md-9 col-xl-10 text-center text-md-left">
    {#each social_links as social_link (social_link.id)}
      <a class="btn btn-dark" href={social_link.link}>
        <i class={social_link.social_link_type.icono} />
        {social_link.social_link_type.title}
      </a>
    {/each}
  </div>
</div>

<style>
  span {
    font-size: 100%;
    color: rgb(0, 0, 0);
  }
  img {
    width: 100px;
    height: 100px;
  }
  a {
    background-color: rgb(0, 0, 0);
    margin: 5px;
    width: 120px;
    margin-top: 0px;
  }
</style>
