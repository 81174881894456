import { writable } from 'svelte/store'
import { getClient } from "svelte-apollo"
import { GET_EVENTOS_ID } from "../helpers/query/eventos"
import { event } from "./event"

const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

function createNewEvents() {
	
  const { subscribe, update, set } = writable([])
    
  return {
    subscribe,
    init: async () => {
      try {
        /* Busco en el servidor los ids de los eventos */
        await getClient().query({query: GET_EVENTOS_ID})
          .then(res => {
            let events_id = res.data.eventos.reduce((array, element)=>{
              array.push(element.id)
              return array
            },[])
            /* Busco en local los ids de los eventos ya vistos */
            const persist = localStorage.getItem("events_seen")
            const events_seen = persist ? JSON.parse(persist) : []
            /* Se comprueba si hay eventos no vistos */
            const update_event = !equals(events_id, events_seen)
            if (update_event===true) {
              event.addEvent("newNews")
              set(events_id)
            }                  
          })
      } catch(e) {
        console.log(e)
      }
    },
    update_alert: () =>{
      update( prev => {
        if(prev.length != 0){
          localStorage.setItem("events_seen", JSON.stringify(prev))
          event.removeEvent()
        }
        return prev
      })
    }
  }      
} 

export const new_events = createNewEvents()