<script>
  import SvelteMarkdown from 'svelte-markdown';

  import { onMount } from 'svelte';
  import { Loading, ErrorHandler } from '../components';
  import { getClient, query } from 'svelte-apollo';
  import { GET_AYUDAS } from '../helpers/query/ayudas';

  onMount(async () => {
    window.scrollTo(0, 0);
  });

  const client = getClient();
  const eventos = query(client, { query: GET_AYUDAS });
</script>

<div class="sidebar-brand-text mx-3">
  <span style="color: rgb(255,255,255);font-size: 20px;" data-cy="help-label">
    Ayuda y acerca de nosotros<br />
  </span>
</div>

{#await $eventos}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:then result}
  <div data-cy="help-card">
    {#each result.data.ayudas as help (help.id)}
      <div
        class="card"
        style="margin: 0 px;margin-top: 10px;margin-bottom: 10px;"
      >
        <div class="card-body">
          <h4 class="card-title" style="color: rgb(0,0,0);">
            {help.title}
          </h4>
          {#if help.subtitle}
            <h5 class="card-subtitle" style="color: rgb(0,0,0);">
              {help.subtitle}<br />
            </h5>
          {/if}
          <p class="card-text" style="color: rgb(0,0,0);">
            <SvelteMarkdown source={help.description} />
          </p>
        </div>
      </div>
    {/each}
  </div>
{:catch error}
  <ErrorHandler {error} />
{/await}
