<script>
  import { onMount } from 'svelte';
  import { List, ItemPeriodico, Loading, ErrorHandler } from '../components';
  import { getClient, query } from 'svelte-apollo';
  import { GET_PERIODICOS } from '../helpers/query/periodicos';

  onMount(async () => {
    window.scrollTo(0, 0);
  });

  const client = getClient();
  const peridicos = query(client, { query: GET_PERIODICOS });
</script>

<div class="sidebar-brand-text mx-3">
  <span
    style="color: rgb(255,255,255);font-size: 20px;"
    data-cy="newspaper-label"
  >
    Otros medios<br />
  </span>
</div>
{#await $peridicos}
  <div class="d-flex justify-content-center pt-3">
    <Loading />
  </div>
{:then result}
  <List
    items={result.data['periodicos']}
    component={ItemPeriodico}
    _class={'border rounded shadow-sm mt-3'}
    _datacy="otros-medios-list"
  />
{:catch error}
  <ErrorHandler {error} />
{/await}
