<script>
  import List from '../components/List.svelte';

  export let items = [],
    list_component = null;
</script>

<div
  role="tablist"
  id="accordion-1"
  style="margin-top: 10px;"
  data-cy="tablist"
>
  {#each items as item (item.id)}
    <div class="card">
      <div class="card-header" role="tab" style="background-color: rgb(0,0,0);">
        <h5 class="mb-0">
          <i
            class="fa fa-sort-down"
            style="color: rgb(255,255,255);margin-right: 5px;"
          />
          <a
            data-toggle="collapse"
            id={'acordion-' + item.id}
            aria-expanded="false"
            aria-controls={'accordion-1 .item-' + item.id}
            href={'#accordion-1 .item-' + item.id}
            style="color: rgb(255,255,255);"
          >
            {item.title}
          </a>
        </h5>
      </div>
      <div
        class={'collapse item-' + item.id}
        role="tabpanel"
        data-parent="#accordion-1"
      >
        <div class="card-body">
          <List items={item.items} component={list_component} />
        </div>
      </div>
    </div>
  {/each}
</div>
