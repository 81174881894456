import { player } from '../models'

function createMediaSession() {
  return {
    /* Inicializa el estado y la radio */
    init: () => {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.setActionHandler('play', async () => {
          player.play()
        })
    
        navigator.mediaSession.setActionHandler('pause', () => {
          player.pause()
        })
      }
    },
    /* Cambia los iconos */
    change: (name, fm, location_name, url_icon) => {
      if ("mediaSession" in navigator) 
        navigator.mediaSession.metadata = new MediaMetadata({
          title: name,
          artist: fm,
          album: location_name,
          artwork: [
            { src: url_icon,   sizes: '96x96',   type: 'image/png' },
            { src: url_icon, sizes: '128x128', type: 'image/png' },
            { src: url_icon, sizes: '192x192', type: 'image/png' },
            { src: url_icon, sizes: '256x256', type: 'image/png' },
            { src: url_icon, sizes: '384x384', type: 'image/png' },
            { src: url_icon, sizes: '512x512', type: 'image/png' },
          ]
        })   
    },
  }
}

export const mediaSession = createMediaSession()